import React, { useEffect } from 'react';
import Banners from '../../Components/Banners';
import BestSeller from '../../Components/BestSellerCard';
import CarouselFade from '../../Components/Carousel';
import CartAccount from '../../Components/CartAccount/CartAccount';
import HotDeals from '../../Components/HotDeals';
import NewArrivals from '../../Components/NewArrivals';
import TopDeals from '../../Components/TopDeals';

const Home = (props) => {

  console.log("hello from HomePage");

  useEffect(() => {
    console.log("Hello", props);
  },)
  
  

  
  return (
    <div style={{position: "relative"}}>
        <CarouselFade />
        <Banners  />
        <NewArrivals sectionRef={props.sectionRef} t={props.t} homePageSearchedResultProducts={props.homePageSearchedResultProducts} />
        {/* <TopDeals t={props.t} /> */}
        {/* <HotDeals t={props.t} /> */}
        {/* <BestSeller t={props.t}/> */}
        {/* <CartAccount setgolobalKeyword={props.setgolobalKeyword} searchKeyword={props.searchKeyword} setSearchKeyword={props.setSearchKeyword} login={props
          .login} setlogin={props.setlogin}/> */}
    </div>
  )
}

export default Home