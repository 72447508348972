import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.css";

import NewArrivalsCard from "./NewArrivalsCard";

const NewArrivals = ({
  t,
  sectionRef,
  homePageSearchedResultProducts,
  vendor_name,
}) => {
  const [newArrivalProducts, setnewArrivalProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://ap.madeinbangladesh.info/api/products?category=&vendor_name="+vendor_name)
      .then((response) => response.json())
      .then((data) => {setnewArrivalProducts(data); setLoading(false);});
    
  }, [vendor_name]);

  let productsToShow = <h1>Loading...</h1>;
  let heading = (
    <>
      <h1>{t("NewArrivals")}</h1>
    </>
  );
  if (homePageSearchedResultProducts && homePageSearchedResultProducts.length) {
    productsToShow = homePageSearchedResultProducts.map((product) => (
      <div className="col-md-3">
        <NewArrivalsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));

    heading = (
      <>
        <h1>Search Result</h1>
      </>
    );
  } else if (newArrivalProducts.length) {
    productsToShow = newArrivalProducts.slice(0, 20).map((product) => (
      <div className="col-md-3">
        <NewArrivalsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));
  } else if(!loading) {
    productsToShow = <h1>No products to show</h1>;
  }

  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">{heading}</div>
      <div ref={sectionRef} className="row gy-5">
        {/* {newArrivalProducts.length &&
          newArrivalProducts.map((product) => (
            <div className="col-md-3">
              <NewArrivalsCard
                productObj={product}
                title={product.name}
                price={`৳${product.regular_price}`}
                image={product.imageUrl}
                t={t}
              />
            </div>
          ))} */}
        {productsToShow}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendor_name: state.globalState.selectedProductVendorName,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewArrivals);
