import React, { useEffect } from 'react';
import Login from '../Login/Login';
import Shoppings from '../Shoppings';
import CategoriesBar from './CategoriesBar';
import Footer from './Footer';
import Header from './Header';
import TopHeader from './TopHeader';



const Layout = ({children, changeLn, t, login, setlogin, handleSectionRef, setHomePageSearchedResultProducts}) => {

  useEffect(() => {
    console.log("from layout", login);
  }, [login])

  


  return (
    <>
        
        <TopHeader setlogin={setlogin} t={t} changeLn={changeLn} />        
        <Header handleSectionRef={handleSectionRef} t={t} setHomePageSearchedResultProducts={setHomePageSearchedResultProducts} />
        <CategoriesBar t={t} />
            {children}
        <Shoppings t={t} />
        <Footer t={t} />
        {login && (
          <Login show={login} setlogin={setlogin} />
        )}

        {/* <AddToCartModal /> */}
    </>
  )
}

export default Layout