import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Myaccount from '../../Components/MyAccount/Myaccount';
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import './index.css';
const AddressBook = () => {
  return (
    <div>
       <CartBannerComponent title="Register" />
    <div className="container">
      <div className="row content">
      <div className="col-md-9 decration">
            <div className="row">
              <div className="col-md-6"> 
                <Card >
                  <Card.Body>
                    <h1>New Customer</h1>
                    <h6>Register Account</h6>
                    <Card.Text>
                      <p>By creating an account you will be able to shop faster, be up to date on an order's status, and keep track of the orders you have previously made.</p>
                    </Card.Text>
                    <Button variant="secondary">CONTINUE</Button>{' '}
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-6">
                <Card >
                  <Card.Body>
                    <h1>Returning Customer</h1>
                    <h6>I am a returning customer</h6>
                    <form action="">
                      <div className="form-group required-wish">
                        <label htmlFor="input-E-mail-Address" className=' control-label-wish'>E-mail Address</label>
                        <div className="">
                          <input type="text" name='E-mail Address' placeholder='E-mail Address' id="input-email" className='from-control' />
                        </div>
                      </div>
                      <div className="form-group required-wish">
                        <label htmlFor="input-Passward" className=' control-label-wish'>Passward </label>
                        <div className="">
                          <input type="text" name='Passward ' placeholder='Passward' id="input-Passward" className='from-control' />
                        </div>
                      </div>
                      <a href="" >
                        <p className="hoverColor">Forgot the password</p>
                      </a>
                    </form>
                    <Button variant="secondary">LOGIN</Button>{' '}
                    <a href=""style={{ backgroundColor: "#dd4b39" }}  className='wishlis-a'>
                      <i  class="fa fa-google fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href="" style={{ backgroundColor: "#3b5998" }} className='wishlis-a'>
                      <i  class="fa fa-facebook fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href=""style={{ backgroundColor: "#55acee" }} className='wishlis-a' >
                      <i  class="fa fa-twitter fa-fw" aria-hidden="true"></i>
                    </a>
                    <a href=""style={{ backgroundColor: "#0e76a8" }}className='wishlis-a' >
                      <i  class="fa fa-linkedin fa-fw" aria-hidden="true"></i>
                    </a>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        <Myaccount/>
      </div>
    </div>
    </div>
  )
}

export default AddressBook