import React from 'react'
import ProdectBanners from '../../Components/ProdectBanner'
const ProdectDetails = () => {
  return (
    <div>
        <ProdectBanners/>
    </div>
  )
}

export default ProdectDetails