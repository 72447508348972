import React, { useEffect, useState } from 'react';
import NewArrivalsCard from '../../Components/NewArrivals/NewArrivalsCard';

const Search = ({ golobalKeyword, searchKeyword,t }) => {
  const [searchResults, setsearchResults] = useState([])

  useEffect(() => {
    fetch(
      "https://faizainc-backend.techknowgram.net/api/search?q=" + golobalKeyword
    )
      .then((response) => response.json())
      .then((data) => setsearchResults(data)());
  }, [golobalKeyword])

  

  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">
        <h1>{t("Search--")}{searchKeyword}</h1>
      </div>
      <div className="row gy-5">
        {searchResults.length ?
          (searchResults.map((product) => (
            <div className="col-md-3">
              <NewArrivalsCard
                // title={t("phone")}
                productObj={product}
                title={product.name}
                price={`৳${product.regular_price}`}
                // price={product.regular_price}
                image={product.imageUrl}
                t={t}
              />
            </div>
          )) ): <h1>Loading....</h1>}
      </div>
    </div>
    );
  };
export default Search