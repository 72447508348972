import React from "react";
import Myaccount from "../../Components/MyAccount/Myaccount";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import "./index.css";
const WishList = () => {
  return (
    <div>
      <CartBannerComponent title="Wish List" />
      <div className="container">
        <div className="row content">
          <div className="col-md-9 decration">
            <div className="row">
              <h1>Wish List</h1>
            </div>
          </div>
          <Myaccount />
        </div>
      </div>
    </div>
  );
};

export default WishList;
