import React from "react";
import { Link } from "react-router-dom";
import Banner from "../../assets/images/baner.png";

const CartBannerComponent = (props) => {
  
  return (
    <div>
      <div className="container">
        <div className="row m-4">
          <div className="col-md-12">
            <div className="product-details-banner-wrapper">
              <img className="img-fluid w-100" src={Banner} alt="" />
              <div className="overlay d-flex justify-content-center align-items-center">
                <div className="banner-inner">
                  <h5 className="text-uppercase">{props.title}</h5>
                  <div className="d-flex align-items-center">
                    <Link to={"/"}>
                      <i className="fa fa-home"></i>
                    </Link>

                    <i className="fa fa-chevron-right"></i>
                    <span>{props.title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartBannerComponent;
