import React, { useEffect } from "react";
import { connect } from "react-redux";
import { cartOperations } from "../../state/ducs/cart";
import { globalOperations } from "../../state/ducs/globalState";
import VendorProducts from "../VendorProducts";
import "./productDetailsMain.css";

const VendorBannerCard = (props) => {
  

  useEffect(() => {
    window.scrollTo(0, 400);
  }, []);


  return (
    <>
      <VendorProducts t={props.t} newArrivalProducts={props.products} />
    </>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = {
  addToCart: cartOperations.addToCart,
  showAddToCartModal: globalOperations.showAddToCartModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorBannerCard);
