import React from "react";
import "./index.css";

import NewArrivalsCard from "./NewArrivalsCard";

const NewArrivalsForCatPage = ({ t, newArrivalProducts, title }) => {
  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">
        <h1 className="text-uppercase">{title} Products</h1>
        {/* <ul className="d-flex align-items-center">
          <li>{t("cam")}</li>
          <li>{t("comp")}</li>
          <li>{t("desk")}</li>
          <li>{t("lapnN")}</li>
        </ul> */}
      </div>
      <div className="row gy-5">
        {newArrivalProducts.length &&
          newArrivalProducts.map((product) => (
            <div className="col-md-3">
              <NewArrivalsCard
                // title={t("phone")}
                productObj={product}
                title={product.name}
                price={`৳${product.regular_price}`}
                // price={product.regular_price}
                image={product.imageUrl}
                t={t}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default NewArrivalsForCatPage;
