import * as types from "./types";

const initialState = {
  //   activeCategory: {},
  //   isLeftMenuActive: true,
  //   categories: [],
  showAddToCartModal: false,
  setgolobalKeyword: "",
  selectedProductVendorName: "",
};

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_ADD_TO_CART_MODAL:
      return {
        ...state,
        showAddToCartModal: true,
      };

    case types.CLOSE_ADD_TO_CART_MODAL:
      return {
        ...state,
        showAddToCartModal: false,
      };

    case types.SET_SELECTED_PRODUCT_VENDOR_NAME:
      const { vendor_name } = action.payload;
      return {
        ...state,
        selectedProductVendorName: vendor_name,
      };

    default:
      return state;
  }
}

export default globalReducer;
