import React, { useState } from 'react';
import './Login.css';

const Login = ({ onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSwitch = () => {
    setIsLogin(!isLogin);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLogin) {
      console.log('Logging in with', { email, password });
      // Handle login
    } else {
      console.log('Registering with', { email, password });
      // Handle registration
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-content">
          <div className="tabs">
            <button 
              className={`tab ${isLogin ? 'active' : ''}`} 
              onClick={() => setIsLogin(true)}
            >
              Login
            </button>
            <button 
              className={`tab ${!isLogin ? 'active' : ''}`} 
              onClick={() => setIsLogin(false)}
            >
              Register
            </button>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <label>Email Address *</label>
              <input 
                type="email" 
                placeholder="Email Address" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Password *</label>
              <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
            </div>
            <div className="additional-options">
              <label>
                <input type="checkbox" /> Remember me
              </label>
              <a href="#" className="forgot-password">Forgot your password?</a>
            </div>
            <button type="submit" className="submit-button">
              {isLogin ? 'Login' : 'Register'}
            </button>
            <div className="new-user-discount">
              New user discount applies only to full price items.
              <br />
              By providing your email address, you agree to our 
              <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a>.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
