import { Formik } from "formik";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Myaccount from "../../Components/MyAccount/Myaccount";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";

import { authOperations } from "../../state/ducs/auth";

const Login = ({
  setlogin,
  loading,
  error,
  isAuthenticated,
  authRedirectPath,
  signIn,
  setAuthRedirectPath,
}) => {
  const navigate = useNavigate();

  const OnContinueHandler = () => {
    navigate("/register");
  };
  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const handleLoginSubmit = (values) => {
    console.log("loginformvalues", values);
    signIn(values.email, values.password);
  };

  let authRedirect = null;
  if (isAuthenticated) {
    authRedirect = <Navigate to={authRedirectPath} />;
  }

  useEffect(() => {
    //you can set condition here upon which you want to redirect to which path
    setAuthRedirectPath("/myAccounts");
  }, []);

  return (
    <div>
      {authRedirect}
      <CartBannerComponent title="Login" />
      <div className="container">
        <div className="row content">
          <div className="col-md-9 decration">
            <div className="row">
              <div className="col-md-6">
                <Card>
                  <Card.Body>
                    <h1>New Customer</h1>
                    <h6>Register Account</h6>
                    <Card.Text>
                      <p>
                        By creating an account you will be able to shop faster,
                        be up to date on an order's status, and keep track of
                        the orders you have previously made.
                      </p>
                    </Card.Text>
                    <Button variant="secondary" onClick={OnContinueHandler}>
                      CONTINUE
                    </Button>{" "}
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-6">
                <Card>
                  <Card.Body>
                    <h1>Returning Customer</h1>
                    <h6>I am a returning customer</h6>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validationSchema={loginSchema}
                      onSubmit={handleLoginSubmit}
                    >
                      {({
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group required-wish">
                            <label
                              htmlFor="user-email"
                              className=" control-label-wish"
                            >
                              E-mail Address
                            </label>
                            <div className="">
                              <input
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="E-mail Address"
                                id="user-email"
                                className="from-control"
                              />
                              {touched.email && errors.email && (
                                <div style={{ color: "red" }}>
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="form-group required-wish">
                            <label
                              htmlFor="user-password"
                              className="control-label-wish"
                            >
                              Passward{" "}
                            </label>
                            <div className="">
                              <input
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Passward"
                                id="user-password"
                                className="from-control"
                              />
                              {touched.password && errors.password && (
                                <div style={{ color: "red" }}>
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                          <a href="">
                            <p className="hoverColor">Forgot the password</p>
                          </a>
                          <button type="submit" className="btn btn-secondary">
                            LOGIN
                          </button>{" "}
                        </form>
                      )}
                    </Formik>
                    <a
                      href=""
                      style={{ backgroundColor: "#dd4b39" }}
                      className="wishlis-a"
                    >
                      <i class="fa fa-google fa-fw" aria-hidden="true"></i>
                    </a>
                    <a
                      href=""
                      style={{ backgroundColor: "#3b5998" }}
                      className="wishlis-a"
                    >
                      <i class="fa fa-facebook fa-fw" aria-hidden="true"></i>
                    </a>
                    <a
                      href=""
                      style={{ backgroundColor: "#55acee" }}
                      className="wishlis-a"
                    >
                      <i class="fa fa-twitter fa-fw" aria-hidden="true"></i>
                    </a>
                    <a
                      href=""
                      style={{ backgroundColor: "#0e76a8" }}
                      className="wishlis-a"
                    >
                      <i class="fa fa-linkedin fa-fw" aria-hidden="true"></i>
                    </a>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <Myaccount />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = {
  signIn: authOperations.auth,
  setAuthRedirectPath: authOperations.setAuthRedirectPath,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
