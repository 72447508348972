import React from 'react'
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import Myaccount from '../../Components/MyAccount/Myaccount';

const ForgottenPassword = () => {
  return (
    <div> <CartBannerComponent title="Register" />
    <div className="container">
      <div className="row content">
        <div className="col-md-9 decration">
          <h1>Register Account</h1>
          <p>
            If you already have an account with us, please login at the
            <a href=""> login page.</a>
          </p>
          <form action="">
            <fieldset id="accounts">
              <legend className='legender'>Your Personal Details</legend>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>First Name</label>
                <div className="col-sm-10">
                  <input type="text" name='firstname' placeholder='First Name' id="input-firstname" className='from-control' />
                </div>
              </div>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>Last Name</label>
                <div className="col-sm-10">
                  <input type="text" name='lastname' placeholder='Last Name' id="input-firstname" className='from-control' />
                </div>
              </div>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>E-Mail</label>
                <div className="col-sm-10">
                  <input type="text" name='email' placeholder='E-Mail' id="input-firstname" className='from-control' />
                </div>
              </div>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>Telephone</label>
                <div className="col-sm-10">
                  <input type="text" name='firstname' placeholder='Telephone' id="input-telephone" className='from-control' />
                </div>
              </div>
            </fieldset>
            <fieldset >
              <legend className='legender'>Your Password</legend>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>Password</label>
                <div className="col-sm-10">
                  <input type="text" name='firstname' placeholder='Password' id="input-firstname" className='from-control' />
                </div>
              </div>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>Password Confirm</label>
                <div className="col-sm-10">
                  <input type="text" name='lastname' placeholder='Password Confirm' id="input-firstname" className='from-control' />
                </div>
              </div>
            </fieldset>
            <fieldset >
              <legend className='legender'>Newsletter</legend>
              <div className="form-group required">
                <label htmlFor="input-first Name" className='col-sm-2 control-label'>Subscribe</label>
                <div className="col-sm-10">
                  <label htmlFor="" className='radio-inline'> <input type="radio" name='newslatter' value={1} /> Yes</label>
                  <label htmlFor="" className='radio-inline'> <input type="radio" name='newslatter' value={0} checked="checked" /> No </label>
                </div>
              </div>
            </fieldset>
          </form>
          <div className="buttons">
            <div className="pull-right">
              I have read and agree to the
              <a href="" className='agree'>
                <b> Privacy Policy </b>
              </a>
              <input type="checkbox" name="agree" value={1} />
              <input type="submit" value={"continue"} className="btn btn-primary" />
            </div>
          </div>
        </div>
        <Myaccount/>
      </div>
    </div></div>
  )
}

export default ForgottenPassword