import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      catAll: "All Categories",
      searchKeyWord: "Search Keyword",
      search: "Search",
      shoppingCart: "Shopping Cart",
      myAcc: "MY ACCOUNT",
      wishList: "Wish List",
      callUs: "Call Us",
      NewArrivals: "New Arrivals",
      TopDeals: "Top Deals",
      HotDeals: "Hot Deals",
      Categories: "Categories",
      hotDeals: "Hot Deals of the Week",
      smartphone: "Smartphone",
      elComp: "Electronic Computer",
      tabs: "Tablets",
      camnPh: "Camera & Photo",
      smntb: "Smartphone & Tablets",
      collect: "Collection",
      pages: "Pages",
      hots: "Hot Sales",
      email: "Email",
      mcb: "Macbook",
      bansub: "Now with the ultimate full hd display",
      spnow: "Shop Now",
      svaio: "Sony VAIO",
      cptr: "Computer",
      phone: "Phone",
      headphone: "Headphone",
      addc: "Add to Cart",
      bseller: "Best Seller",
      appc30: "Apple Cinema 30",
      caneo5d: "Canon EOS 5D",
      addr: "ADDRESS",
      taito: "Taito 1-29-3, Taito-ku, Tokyo",
      jap: "Japan",
      cus: "CUSTOMER SERVICES",
      cont: "Contact Us",
      ret: "Returns",
      sit: "Site Map",
      mya: "MY ACCOUNT",
      bra: "Brands",
      gif: "Gift Certificates",
      aff: "Affiliates",
      spe: "Specials",
      cop: "Copyright © 2022 by TechKnowGram.com. All Rights Reserved.",
      fre: "Free Shipping",
      frep: "Free shipping on all Japan orders",
      mong: "Money Guarantee",
      thirt: "30 days money back guarantee",
      saf: "Safe Shopping",
      safg: "Safe shopping guarantee",
      onl: "Online Support",
      wes: "We support online 24 hours a day",
      cam: "Cameras",
      comp: "Components",
      desk: "Desktops",
      lapnN: "Laptops & Notebooks",

      mon: "Monitors",
      print: "Printers",
      scann: "Scanners",
      webcam: "Web Cameras",
      mice: "Mice & Trackballs",
      pc: "PC",
      mc: "Mac",
      soft: "Software",
      abus: "About Us",
      serv: "Services",
      fq: "FAQs",
      support: "Support 27/7 page",
      typ: "Typography",
      phg: "Photo Gallery",
      smap: "SiteMap",
      p4: "Page 404",
      coms: "Coming Soon",
      test: "Testimonials",
      mor: "Most Rated"

    },
  },

  jp: {
    translation: {
      catAll: "すべてのカテゴリ",
      searchKeyWord: "検索語",
      search: "探す",
      shoppingCart: "ショッピングカート",
      myAcc: "マイアカウント",
      wishList: "ウィッシュリスト",
      callUs: "お電話ください",
      NewArrivals: "新着",
      TopDeals: "人気のセール",
      HotDeals: "人気のセール",
      Categories: "カテゴリー",
      hotDeals: "今週の特売品",
      smartphone: "スマートフォン",
      elComp: "電子計算機",
      tabs: "タブレット",
      camnPh: "カメラと写真",
      smntb: "スマートフォン＆タブレット",
      collect: "コレクション",
      pages: "ページ",
      hots: "ホットセール",
      email: "Eメール",
      mcb: "マックブック",
      bansub: "究極のフル HD ディスプレイを搭載",
      spnow: "今すぐ購入",
      svaio: "ソニーVAIO",
      cptr: "コンピューター",
      phone: "電話",
      headphone: "ヘッドホン",
      addc: "カートに追加",
      bseller: "ベスト・セラー",
      appc30: "アップル シネマ 30",
      caneo5d: "キヤノン EOS 5D",
      addr: "アドレス",
      taito: "たいと １ー２９ー３、 帯トーク、 と居",
      jap: "日本",
      cus: "顧客サービス",
      cont: "お問い合わせ",
      ret: "戻り値",
      sit: "サイトマップ",
      bra: "ブランド",
      gif: "商品券",
      aff: "アフィリエイト",
      spe: "特別な",
      cop: "著作権 © 2022 by TechKnowGram.com. 全著作権所有。",
      fre: "送料無料",
      frep: "日本全国送料無料",
      mong: "金銭保証",
      thirt: "30日間の返金保証",
      saf: "安全なショッピング",
      safg: "安心のお買い物保証",
      onl: "オンラインサポート",
      wes: "24時間オンラインでサポートいたします",
      cam: "カメラ",
      comp: "コンポーネント",
      desk: "デスクトップ",
      lapnN: "ラップトップとノートブック",


      mon: "モニター",
      print: "プリンター",
      scann: "スキャナー",
      webcam: "ウェブカメラ",
      mice: "マウスとトラックボール",
      pc: "パソコン",
      mc: "マック",
      soft: "ソフトウェア",
      abus: "私たちに関しては",
      serv: "サービス",
      fq: "よくある質問",
      support: "27 時間年中無休のサポート ページ",
      typ: "タイポグラフィ",
      phg: "フォトギャラリー",
      smap: "サイトマップ",
      p4: "404ページ",
      coms: "近日公開",
      test: "お客様の声",
      mor: "最も評価の高い"

    },
  },
  sp: {
    translation: {
      paragraph:
        "react-i18next es un poderoso marco de internacionalización para React / React Native que se basa en i18next.",
    },
  },
  hn: {
    translation: {
      paragraph:
        "प्रतिक्रिया-i18next प्रतिक्रिया / प्रतिक्रिया मूल के लिए एक शक्तिशाली अंतर्राष्ट्रीयकरण ढांचा है जो i18next पर आधारित है।",
    },
  },
  fr: {
    translation: {
      paragraph:
        "react-i18next est un puissant framework d'internationalisation pour React / React Native basé sur i18next.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "sp",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
