import React from "react";
import "./index.css";

import TopDealsCard from "./TopDealsCard";

const VendorProducts = ({ newArrivalProducts, t }) => {

  console.log("t", t);

  let productsToShow = <h1>Loading...</h1>;
  let heading = (
    <>
      <h1>Products</h1>
    </>
  );
  if (newArrivalProducts.length) {
    productsToShow = newArrivalProducts.slice(0, 20).map((product) => (
      <div className="col-md-3">
        <TopDealsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));
  } else {
    productsToShow = <h1>Loading...</h1>;
  }

  return (
    <div className="container p-4 new-arrivals-container">
      <div className="d-flex align-items-center">{heading}</div>
      <div className="row gy-5">
        {productsToShow}
      </div>
    </div>
  );
};

export default VendorProducts;
