import React from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "./index.css";

const TopHeader = ({ changeLn, t, setlogin, isAuthenticated }) => {
  const handleLanguageChange = (e) => {
    changeLn(e.target.value);
  };

  const navigate = useNavigate();

  const gotToMyAccount = () => {
    navigate("/myAccount");
  };

  return (
    <>
      <div className="container" style={{ padding: "7px" }}>
        <div className="row">
          <div
            className="col-12 col-md-6 d-flex justify-content-start align-items-center mb-2 mb-md-0"
            style={{ fontFamily: "Bai Jamjuree, sans-serif", fontSize: "14px", fontWeight: "500px" }}
          >
            You are a student and students get 20% discount.
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
            <div className="d-flex flex-wrap justify-content-end">
              <Link className="linkColor" to="#">
                Store Locator
              </Link>
              <Link className="linkColor" to="#">
                Order Tracking
              </Link>
              <Link className="linkColor" to="#">
                FAQs
              </Link>
            </div>
            <select
              className="language"
              name="language"
              id="language"
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="jp">Japanese</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bb"></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
