import React from "react";
import FooterImage from "../../../assets/images/sdf.png";
import "./index.css";

const Footer = ({ t }) => {
  return (
    <div>


<footer class="footer">
        <div class="footer-content">
            <div class="footer-column">
                <h3>Programs</h3>
                <ul>
                    <li><a href="#">Corporate</a></li>
                    <li><a href="#">One-to-One</a></li>
                    <li><a href="#">Consulting</a></li>
                </ul>
            </div>
            <div class="footer-column">
                <h3>Service</h3>
                <ul>
                    <li><a href="#">Training</a></li>
                    <li><a href="#">Coaching</a></li>
                    <li><a href="#">Consulting</a></li>
                </ul>
            </div>
            <div class="footer-column">
                <h3>Contact</h3>
                <ul>
                    <li><a href="#">Home</a></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Contact</a></li>
                </ul>
            </div>
            <div class="footer-column">
                <h3>Newsletter</h3>
                <div class="newsletter">
                    <input type="email" placeholder="Your email..."/>
                    <button>Subscribe</button>
                </div>
                <div class="social-icons">
                    <a href="#" aria-label="WhatsApp">W</a>
                    <a href="#" aria-label="YouTube">Y</a>
                    <a href="#" aria-label="Instagram">I</a>
                    <a href="#" aria-label="Facebook">f</a>
                    <a href="#" aria-label="LinkedIn">in</a>
                    <a href="#" aria-label="Twitter">t</a>
                </div>
              
            </div>
        </div>
        <div class="footer-bottom">
            
            <div class="footer-links">
                <a href="#">© 2024 TechKnowGram.com</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Terms of Service</a>
            </div>
        </div>
    </footer>



    </div>
  );
};

export default Footer;
