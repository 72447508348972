import React from 'react';
import { useParams } from 'react-router-dom';
import VendorBanners from '../../Components/VendorBanner';

const VendorDetails = ({t}) => {
  const {vendorId}= useParams();

  return (
    <div>
        <VendorBanners t={t} vendorId={vendorId}/>

    </div>
  )
}

export default VendorDetails;