import reducer from "./reducers";

import * as authOperations from "./operations";
import * as authSelectors from "./selectors";

export {
    authOperations,
    authSelectors,
};

export default reducer;
