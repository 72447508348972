import React from "react";
import "./index.css";

import { ShoppingCard } from "./ShoppingCard";

const Shoppings = ({ t }) => {
  return (
    <div>

    </div>
  );
};

export default Shoppings;
